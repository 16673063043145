<template>
  <div class="row mb-5">
    <div class="card shadow-sm">
      <div class="card-body p-5">
        <h5 class="card-title text-center fw-bold fs-3 mb-2">
          Project Information
        </h5>
        <div class="row card-text">
          <div class="col-md-6 col-sm-12 my-3">
            <label for="projectName" class="form-label">Project Name</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control form-black-border"
                placeholder="my-awesome-project"
                aria-label="my-awesome-project"
                v-model="info.name"
              />
              <span class="input-group-text green-bg">ziqni.app</span>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 my-3">
            <label for="displayName" class="form-label"
              >Display Name (Optional)</label
            >

            <input
              class="form-control form-black-border"
              type="text"
              placeholder="My Awesome Project"
              aria-label="My Awesome Project"
              v-model="info.displayName"
            />
          </div>
          <div class="col-md-6 col-sm-12 my-3">
            <label for="roleSelect" class="form-label"
              >What is your role/job title?</label
            >

            <select class="form-select mb-3 form-black-border" 
                v-model="info.role">
              <option selected disabled value="0">
                Select the role that describes you
              </option>
              <option value="1">Developer</option>
              <option value="2">Project manager</option>
              <option value="3">Content manager</option>
              <option value="4">Other</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectInformation',
  props: {
    value: {
      type: Object,
    }
  },
  data() {
    return {
      info: {
        name: "",
        displayName: "",
        role: 0
      }
    }
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.$emit("input", this.info)
      }
    }
  },
  created() {
    if(this.value) {
      this.info = this.value;
    }
  }
};
</script>
