<template>
  <div class="row my-5 bg-banner-create-project">
    <div class="col-lg-5 pt-lg-3 my-5 text-center">
      <h1 class="display-4 fw-bold lh-1 my-5">{{prismicDocument["hero-image-title"][0].text}}</h1>
      <p class="lead my-3">
        {{prismicDocument["hero-image-sub-text"][0].text}}
      </p>
    </div>
    <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden"></div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    prismicDocument: {},
  }
};
</script>
