<template>
  <div class="row mb-5">
    <div class="card shadow-sm plans">
      <div class="card-body p-5">
        <h5 class="card-title text-center fw-bold fs-3 mb-2">
          Available plans
          <div
            style="
              font-size: 18px;
              color: #59a2fd;
              margin: 20px;
              font-weight: 500;
            "
          >
            See which plan is right for you.
          </div>
        </h5>
        <div class="row card-text plan-detail">
          <div class="col-2" style="margin-right: 4rem">
            <div class="col-12 plan-head no-bg">Plans</div>
            <div class="col-12 plan-head no-bg">Users</div>
            <div class="col-12 plan-head no-bg h6">Support and SLAs</div>
            <div class="col-12 plan-head no-bg">Features</div>
            <div class="col-12 plan-head no-bg">Price</div>
            <div class="col-12 plan-head no-bg">&nbsp;</div>
          </div>
          <div
            class="col-2 row text-center p0"
            style="margin: 0px 5px"
            v-for="p of pricingPlans"
            :key="p.id"
          >
            <div class="col-12 plan-head">{{ p.title }}</div>
            <div class="col-12">{{ p.user }}</div>
            <div class="col-12 h6" v-html="p.support"></div>
            <div class="col-12">{{ p.features }}</div>
            <div class="col-12">{{ p.displayPrice }}</div>
            <div class="col-12 no-bg">
              <input
                class="form-check-input"
                type="radio"
                name="planId"
                id="planId"
                :value="p.key"
                v-model="selectedPlan"
                @click="planClick(p)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailablePlans',
  data() {
    return {
      selectedPlan: '',
      pricingPlans: [],
    };
  },
  methods: {
    planClick(i) {
      this.$emit('click', {
        plan: i,
        showView:
          i.key === 'developer'
            ? 'onlyBtn'
            : i.key === 'platinum'
            ? 'talkToUs'
            : 'billing',
      });
    },
  },
  created() {
    this.pricingPlans = require('../../api/plan.api.json');
  },
};
</script>
