<template>
  <div class="row mb-5">
    <div class="card blue-card shadow-sm">
      <div class="card-body p-5">
        <h5 class="card-title text-center fw-bold fs-3 mb-4">
          Billing Information
        </h5>
        <div class="text-center mx-auto my-4" style="width: 500px">
          Consequat porttitor molestie nulla libero fusce Amet proin sed dui dis
          risus aenean felis erat duis, ornare iaculis cubilia. et.
        </div>
        <div class="row card-text">
          <div class="col-12 my-3">
            <div class="form-floating text-dark mb-3">
              <input
                type="text"
                class="form-control border-rounded"
                id="floatingInput"
                placeholder="Name on card"
              />
              <label for="floatingInput">Name on card</label>
            </div>
          </div>
          <div class="col-12 my-3">
            <div class="form-floating text-dark mb-3">
              <input
                type="text"
                class="form-control border-rounded"
                id="floatingInput"
                placeholder="Card number"
              />
              <label for="floatingInput ">Card number</label>
            </div>
          </div>
          <div class="col-6 my-3">
            <div class="form-floating text-dark mb-3">
              <input
                type="text"
                class="form-control border-rounded"
                id="floatingInput"
                placeholder="Expiry Date"
              />
              <label for="floatingInput">Expiry Date</label>
            </div>
          </div>
          <div class="col-6 my-3">
            <div class="form-floating text-dark mb-3">
              <input
                type="text"
                class="form-control border-rounded"
                id="floatingInput"
                placeholder="CSV"
              />
              <label for="floatingInput">CSV</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center my-2">
      <button
        type="button"
        class="btn btn-lg btn-rounded px-4 me-md-2 btn-dark my-1"
      >
        Create Project
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailablePlans",
};
</script>
